/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import metrics from '../metrics';
import { TextField, MenuItem } from '@mui/material';
import Commons from '../functions/Commons';
import { styled } from '@mui/material/styles';


const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "rgb(50,50,50)",
        color: "rgb(50,50,50)"
    },
    ".MuiInputBase-input.Mui-disabled:hover": {
        cursor: "not-allowed"
    }
}));

async function getListitems(url) {
    try {
        const fetchResult = fetch(`${metrics.URL}/get${url}Beton`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: Commons.getAuthorization(),
            },
        });
        const response = await fetchResult;

        return response.json();
    } catch (e) {
        console.warn(e);
    }
}
export default class CustomDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? String(props.value) : props.value === false ? 'false' : '',
            itemList: [],
            error: false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.itemList;
        const newVal = newProps.value;

        if (newProps.hasOwnProperty('itemList') && newValue !== this.state.itemList && newValue) {
            this.setState({
                itemList: newValue.filter((val) => Commons.checkAccessControl(val.role)),
            });
        }
        if (newProps.hasOwnProperty('value') && newVal !== this.state.value && newVal) {
            this.setState({
                value: newVal ? String(newVal) : newVal === false ? 'false' : '',
            });
        }
    }
    componentDidMount() {
        if (this.props.itemList) {
            this.setState({
                itemList: this.props.itemList.filter((val) => Commons.checkAccessControl(val.role)),
            });
        } else {
            getListitems(this.props.name).then((data) => {
                var itemList = this.props.additionalItemList ? [...this.props.additionalItemList, ...data.Items] : data.Items;
                this.setState({ itemList: itemList });
            });
        }
    }
    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.props.isRequired) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }
    handleChange = (val) => {
        if (this.props.onChange) this.props.onChange(val);
        this.setState({ error: false, value: val });
    };
    render() {
        const { label, isRequired, editable } = this.props;
        return (
            <CustomDisableInput
                select
                error={this.state.error}
                label={label}
                style={this.props.style || { width: '100%' }}
                disabled={!editable}
                required={isRequired}
                defaultValue={this.props.defaultValue}
                value={this.state.value}
                onChange={(val) => this.handleChange(val.target.value)}
                margin="normal"
            >
                {this.state.itemList.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                        {option.label || option.name}
                    </MenuItem>
                ))}
            </CustomDisableInput>
        );
    }
}
