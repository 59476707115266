import io from 'socket.io-client';
import metrics from '../metrics.js';

class socket {
  constructor() {
    this._io = null;
  }

  createWS(token) {
    this.io = io(metrics.URLD, {
      query: { token: token, webBeton: true, mobile: false },
    });
  }

  ws() {
    if (this.io) return this.io;

    return false;
  }
}

export default new socket();
