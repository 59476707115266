import React, { useState, useEffect } from 'react';
import metrics from '../metrics';
import api from '../functions/api';
import Commons from '../functions/Commons';
import moment from 'moment';

import { Button } from '@mui/material';

import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import ws from '../functions/ws';

var countObj = {};

const Schedule = (props) => {
    // Scheduler referance object
    const [scheduleObj, setScheduleObj] = useState();
    // Lab takvim ayarları
    const [workHours, setWorkHours] = useState({ highlight: true, start: "08:00", end: "23:59" })
    const [timeScale, setTimeScale] = useState({ enable: true, interval: '60', slotCount: 1, majorSlotTemplate: null, minorSlotTemplate: null })
    const [workDays, setWorkDays] = useState("[1,2,3,4,5]");

    useEffect(() => {
        api.getSchedulerSettings(props.labDbName) // Lab takvim ayarları
            .then(data => {
                if (data) {
                    data.workHours && setWorkHours({ ...data.workHours, highlight: true });
                    data.timeScale && setTimeScale((t) => ({ ...t, ...data.timeScale }));
                    data.workDaysIndex && setWorkDays(data.workDaysIndex);
                }
            })
    }, [props]);

    useEffect(() => {
        calculateHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workHours.start, workHours.end, timeScale.interval])

    const dataManager = new DataManager({
        url: `${metrics.URL}/getScheduleDataBeton/?longitude=${props.selectedRowData?.coordinate?.longitude}&latitude=${props.selectedRowData?.coordinate?.latitude}`,
        // crudUrl: metrics.URL + '/updateScheduleData', // Update engellendi
        headers: [{ authorization: Commons.getAuthorization(), labdbname: props.labDbName, appointmentid: props.rowId }], // Lab takvimi görüntülemek için dbName eklendi
        adaptor: new UrlAdaptor()
    });

    // Takvim başlık özelleştirme
    function headerTemplate(props) {
        return (<div style={{ backgroundColor: "#ffffff", padding: "8px 18px" }}>
            <div style={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: 'flex-end', padding: "5px 10px 5px" }}>
                <div style={{ fontWeight: 500, fontSize: "18px", letterSpacing: "0.48px", height: "22px", marginBottom: 10 }}>{(props.elementType === 'cell') ? 'Boş gün ekle' : 'Detay'}</div>
                <div style={{ fontSize: "13px", letterSpacing: "0.33px", height: "14px" }}>
                    {moment(props.StartTime).format('LL') + " "}
                    <span style={{ fontWeight: 'bold' }}>{moment(props.StartTime).format('LT') + " - " + moment(props.EndTime).format('LT')}</span>
                </div>
            </div>
        </div>);
    }
    // Takvimde hücreye tıklandığında açılan dialog özelleştirme
    function contentTemplate(props) {
        countObj.value = props.Subject;
        return (
            <div>
                <div style={{ height: "30px", display: "flex", flexDirection: 'column', justifyContent: 'center', padding: '0 15px' }}>
                    <div style={{ fontSize: '14px', letterSpacing: '0.33px', height: '24px', padding: '5px' }}>
                        <label style={{ display: 'inline-block', minWidth: '40px' }}>El Terminali</label>:
                        <span style={{ letterSpacing: '0.33px', lineHeight: '14px', paddingLeft: '8px', fontWeight: 'bold' }}>{props.Subject}</span>
                    </div>
                </div>
            </div>
        );
    }
    // Takvim dialog butonları
    function footerTemplate(props) {
        return (
            <div className="quick-info-footer">
                {props.elementType !== "cell" &&
                    <div className="event-footer" >
                        <Button id="confirm-date" cssClass='e-flat' onClick={(e) => buttonClickActions(e)}>Tarih ve Saati onayla</Button>
                    </div>
                }
            </div>
        )
    }

    function buttonClickActions() {
        var startTime = scheduleObj.activeEventData.event.StartTime
        var endTime = scheduleObj.activeEventData.event.EndTime

        var changedObj = {
            ...props.selectedRowData, // Tabloda seçilen radevu bilgileri
            _id: props.rowId, // rowData içerisinde null olarak geldiği için ayrıca id props olarak gönderildi
            Subject: countObj.value, // Lab takvim boş terminal sayısı
            concreteAppointmentDate: [startTime, endTime], // Onaya gönderilen tarih
            labScheduleId: scheduleObj.activeEventData.event._id, // Lab takvim terminal sayısı güncellenmesi için
            isRejected: false // Reddedilmiş randevuların normale dönmesi için
        }
        api.updateScheduleDataBeton(changedObj) // Veri tabanlarını güncelliyor
            .then((resData) => {
                delete changedObj.Subject;
                ws.ws().emit('updateAppointment', changedObj); // Lab ve denetmen tabloları güncelleme
                global.emitter.emit('updateTableData', '/Appointments', { ...changedObj, "appointmentStatus": "Yapı Denetim Onayı Bekleyen" }); // Beton tablo güncelleme
                global.emitter.emit('change', resData.type, resData.message);
            });

        props.setLabScheduleOpen(false);
    }

    function renderCell(args) {
        if ((args.elementType === "dateHeader") && args.date) {
            var cellDate = new Date(args.date.getTime());
            var date = new Date(cellDate.setHours(0, 0, 0));
            if (date.getTime() === new Date(props.appointmentDate).setHours(0, 0, 0, 0)) {
                args.element.classList.add('appointment-date'); // İstenen tarihin renkli gösterilmesi
            }
        }
        args.element.classList.add('appointment-x');
    }

    function calculateHours() {
        // Başlangıç saati ve bitiş saati dakika cinsine çevir
        var startHourParts = workHours.start.split(":");
        var endHourParts = workHours.end.split(":");
        var startHourMinutes = parseInt(startHourParts[0]) * 60 + parseInt(startHourParts[1]);
        var endHourMinutes = parseInt(endHourParts[0]) * 60 + parseInt(endHourParts[1]);

        // Periyotu dakikaya çevir
        var periyotMinutes = parseInt(timeScale.interval);

        // Başlangıç saatinde periyotu çıkar
        var newStartHourMinutes = startHourMinutes - periyotMinutes;
        // Negatif sonuç durumunu kontrol et
        if (newStartHourMinutes < 0) {
            newStartHourMinutes = 1440 + newStartHourMinutes; // 1440 dakika bir günü temsil eder
        }

        // Bitiş saatinde periyotu ekle
        var newEndHourMinutes = endHourMinutes + periyotMinutes;
        // Negatif sonuç durumunu kontrol et
        if (newEndHourMinutes < 0) {
            newEndHourMinutes = 1440 + newEndHourMinutes; // 1440 dakika bir günü temsil eder
        }

        // Sonuçları saat ve dakika olarak formatla
        var newStartHour = ("0" + Math.floor(newStartHourMinutes / 60)).slice(-2) + ":" + ("0" + (newStartHourMinutes % 60)).slice(-2);
        var newEndHour = ("0" + Math.floor(newEndHourMinutes / 60)).slice(-2) + ":" + ("0" + (newEndHourMinutes % 60)).slice(-2);

        if (scheduleObj) {
            scheduleObj.startHour = newStartHour
            scheduleObj.endHour = newEndHour
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <ScheduleComponent
                height='100%'
                width='100%'
                locale='tr'
                views={['Week']}
                readonly={true}
                eventSettings={{ dataSource: dataManager }}
                selectedDate={new Date(props.appointmentDate)} // Takvimin istenen tarihte açılması
                startHour={"07:00"}
                endHour={"20:00"}
                workHours={workHours}
                timeScale={timeScale}
                allowResizing={false}
                firstDayOfWeek={1}
                workDays={workDays}
                quickInfoTemplates={{
                    header: headerTemplate,
                    content: contentTemplate,
                    footer: footerTemplate
                }}
                ref={(schedule) => setScheduleObj(schedule)}
                renderCell={renderCell}
            >
                <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
            </ScheduleComponent>
        </div>
    )
};

export default Schedule