/* eslint-disable no-redeclare */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';

import Draggable from 'react-draggable';
import ConcreteModel from '../models/ConcreteModel';
import InputTypes from '../components/InputTypes';
import Schedule from '../components/Schedule';
import moment from 'moment';
function PaperComponent(props) {
    return (
        <Draggable handle="#draggableConcrete" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
//1 edit 2 oluştur
var inputRefs = [];
var status = "";
export default function ConcreteCreateEdit(props) {

    const [inputListState, setInputListState] = useState([]);
    const [rowId, setRowId] = useState(props.rowId ? props.rowId : null);
    const [labScheduleOpen, setLabScheduleOpen] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState(null);
    var ConcModel = new ConcreteModel();

    useEffect(() => {
        const { openDialog, selectedRowData, inputList, rowId } = props
        setRowId(rowId ? rowId : null);
        if (openDialog) {
            status = selectedRowData.rowData.appointmentStatus;
            setInputListState(inputList);
        }
        else {
            inputRefs = [];
            setRowId(null);
        }

    }, [props]);

    useEffect(() => {
        // Tablo verilerinin Schedule'a aktarılması için
        inputListState.forEach((item) => {
            ConcModel.Concrete[item.dataKey] = item?.value;
        });
    }, [inputListState, rowId, ConcModel.Concrete])

    function updateRender(data) {
        inputListState.find((val) => val.dataKey === 'yibfno').value = data.yibfno;
        setInputListState([...inputListState])
    };

    return (
        <div>
            <Dialog
                open={props.openDialog}
                onClose={() => props.toggleDialog()}
                scroll="paper"
                maxWidth="sm"
                PaperComponent={PaperComponent}
                aria-labelledby="draggableConcrete"
            >
                <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableConcrete">
                    {props.dialogTitle}
                </DialogTitle>
                <DialogContent style={{ flexGrow: 1 }} dividers>
                    <Grid container justify="space-around">
                        {
                            inputListState.map((element, i) => {
                                return InputTypes(element, {
                                    inputRefs: inputRefs,
                                    updateParent: updateRender,
                                });
                            })}
                    </Grid>

                </DialogContent>
                <DialogActions style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse"
                }} >
                    <div>
                        <Button onClick={() => props.toggleDialog()} color="primary">
                            Kapat
                        </Button>
                        {(status === "Beton Onay Bekleyen" || status === "Reddedildi") &&
                            <Button
                                color="primary"
                                onClick={() => {
                                    setAppointmentDate(inputRefs["requestedAppointmentDate"].state.value); // inputList'in olduğu dialog kapanınca referanslar siliniyor. Bu yüzden tarih state'e kaydedildi.
                                    setLabScheduleOpen(true);
                                    props.toggleDialog();
                                }}
                            >
                                LAB TAKVIM
                            </Button>
                        }
                    </div>
                </DialogActions>
            </Dialog>
            {/* Schedule dialog */}
            <Dialog
                open={labScheduleOpen}
                onClose={() => { setLabScheduleOpen(false) }}
                scroll="paper"
                maxWidth="xl"
                PaperComponent={PaperComponent}
                aria-labelledby="draggableConcrete"
            >
                <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableConcrete">
                    {`${props.dialogTitle + " - " + moment(appointmentDate).format('LL')}`}
                </DialogTitle>

                <DialogContent style={{ flexGrow: 1 }} dividers>

                    <Schedule
                        labDbName={props.selectedRowData && props.selectedRowData.rowData.labDbName}
                        appointmentDate={appointmentDate}
                        setLabScheduleOpen={setLabScheduleOpen}
                        selectedRowData={ConcModel.Concrete}
                        rowId={props.rowId}
                    />

                </DialogContent>

                <DialogActions style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse"
                }} >
                    <div>
                        <Button onClick={() => { setLabScheduleOpen(false) }} color="primary">
                            Kapat
                        </Button>
                    </div>
                </DialogActions>

            </Dialog>
        </div >
    );

}
