/* eslint-disable no-unused-vars */
import React from 'react';

import Commons from '../functions/Commons';
import history from '../functions/history';
import { Divider, Tooltip, SvgIcon, ListItemIcon, ListItemText, ListSubheader, ListItemButton } from '@mui/material';
import { observer } from 'mobx-react';

const CalendarIcon =
    'M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z';
const UsersIcon =
    'M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z';
const ConcreteComIcon =
    'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z';
const AccountingIcon =
    'M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z';
// const ScheduleIcon = "M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"
function ListItems() {
    function redirect(Path) {
        history.push(Path);
    }
    function renderListItem(icon, Text, Path) {
        return (
            <Tooltip title={Text} placement="bottom-end">
                <ListItemButton
                    selected={Path === history.location.pathname}
                    style={{ color: 'white' }}
                    onClick={() => redirect(Path)}
                >
                    <ListItemIcon style={{ color: 'white' }}>
                        <SvgIcon>
                            <path d={icon} />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={Text} />
                </ListItemButton>
            </Tooltip>
        );
    }

    const mainListItems = (
        <div>
            {renderListItem(CalendarIcon, 'Randevular', '/Appointments')}
        </div>
    );

    const secondaryListItems = (
        <div>
            <Divider />
            <ListSubheader style={{ color: 'white', backgroundColor: '#006fba', borderRadius: 8 }} inset>
                Yönetim
            </ListSubheader>
            <Divider />

            {/*renderListItem(ConcreteComIcon, 'Değişkenler', '/Constants')*/}
            {renderListItem(UsersIcon, 'Kullanıcılar', '/Users')}
            {/*renderListItem(AccountingIcon, 'Muhasebe', '/Accounting')*/}
        </div>
    );

    return (
        <div>
            {mainListItems}
            {Commons.checkAccessControl(2) && secondaryListItems}
        </div>
    );
}

export default observer(ListItems);
