class ConcreteModel {
    constructor() {
        this.Concrete = {
            _id: '',
            yibfno: '', //true
            constructionName: '', //true
            appointmentStatus: '', //true
            inspector: '', //false
            blockNo: '', //true
            block: '', //false
            section: '',
            floor: '', //true
            castingSection: '', //true
            concreteFormDate: '',
            receivedİronDia: '', //true
            elevation: '',
            concreteMoldDate: '',
            concreteDemouldDate: '',
            concreteClass: '', //true
            castingQTY: '', //true
            receivedCube: '', //true
            constructionPercent: '', //false
            ConcreteCompany: '', //false
            requestDate: '',
            requestedAppointmentDate: '', //false
            appointmentDateToConcrete: '',
            coordinate: ""
        };
    }
}
export default ConcreteModel;
