import React from 'react';
import Commons from '../functions/Commons';
import MultiAutocomplete from './MultiAutocomplete';
import Separator from './Separator';
import CustomDatepicker from './CustomDatepicker';
import CustomDropdown from './CustomDropdown';
import CustomTextInput from './CustomTextInput';
import Autocomplete from './Autocomplete';
import CustomCheckBox from './CustomCheckBox';
import { Grid } from '@mui/material';
import CustomDateRange from './CustomDateRange';
import CustomMultipleDropdown from './CustomMultipleDropdown';

const TEXTINPUT = 1;
const DROPDOWN = 2;
const DATETIME = 3;
const MULTIAUTOCOMPLETE = 4;
const AUTOCOMPLETE = 5;
const SEPARATOR = 6;
const CHECKBOX = 7;
const DATETIMERANGE = 8;

export default function returnInput(element, passedProps, isFilter) {

    if (isFilter) {
        if (Commons.checkAccessControl(element.role) && element.inputVisible !== false)
            switch (element.componentType) {
                case TEXTINPUT:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomTextInput
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                keyboardType={element.keyboardType}
                                editable={element.editable}
                                label={element.title}
                            />
                        </Grid>
                    );
                case DROPDOWN:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            {element.isMultiple ? (
                                <CustomMultipleDropdown
                                    style={passedProps.style}
                                    ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                    isRequired={element.required}
                                    itemList={element.itemList}
                                    value={element.value}
                                    label={element.title}
                                    editable={element.editable}
                                    name={element.dataKey}
                                />
                            ) : (
                                <CustomDropdown
                                    style={passedProps.style}
                                    ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                    isRequired={element.required}
                                    itemList={element.itemList}
                                    value={element.value}
                                    label={element.title}
                                    editable={element.editable}
                                    name={element.dataKey}
                                />
                            )}
                        </Grid>
                    );
                case DATETIME:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 12 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomDateRange
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                label={element.title}
                                editable={element.editable}
                            />
                        </Grid>
                    );
                case MULTIAUTOCOMPLETE:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <MultiAutocomplete
                                style={passedProps.style}
                                itemList={element.itemList}
                                name={element.dataKey}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                keyboardType={element.keyboardType}
                                editable={element.editable}
                                label={element.title}
                            />
                        </Grid>
                    );
                case AUTOCOMPLETE:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <Autocomplete
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                updateParent={passedProps.updateParent}
                                value={element.value}
                                label={element.title}
                                editable={element.editable}
                            />
                        </Grid>
                    );
                case SEPARATOR:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <Separator
                                style={passedProps.style}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                containerStyle={{ marginVertical: 10, width: '95%' }}
                                textStyle={{ fontSize: 17 }}
                            >
                                {element.separatorTitle}
                            </Separator>
                        </Grid>
                    );
                case CHECKBOX:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomCheckBox
                                style={passedProps.style}
                                label={element.title}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                            />
                        </Grid>
                    );
                default:
                    return;
            }
    } else {
        if (Commons.checkAccessControl(element.role) && element.inputVisible !== false)
            switch (element.componentType) {
                case TEXTINPUT:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomTextInput
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                keyboardType={element.keyboardType}
                                editable={element.editable}
                                label={element.title}
                                datetime={element.format === "datetime"}
                            />
                        </Grid>
                    );
                case DROPDOWN:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomDropdown
                                style={passedProps.style}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                isRequired={element.required}
                                itemList={element.itemList}
                                value={element.value}
                                label={element.title}
                                editable={element.editable}
                                name={element.dataKey}
                            />
                        </Grid>
                    );
                case DATETIME:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomDatepicker
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                label={element.title}
                                editable={element.editable}
                            />
                        </Grid>
                    );
                case MULTIAUTOCOMPLETE:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <MultiAutocomplete
                                style={passedProps.style}
                                itemList={element.itemList}
                                name={element.dataKey}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                keyboardType={element.keyboardType}
                                editable={element.editable}
                                label={element.title}
                            />
                        </Grid>
                    );
                case AUTOCOMPLETE:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <Autocomplete
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                updateParent={passedProps.updateParent}
                                value={element.value}
                                label={element.title}
                                editable={element.editable}
                            />
                        </Grid>
                    );
                case SEPARATOR:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <Separator
                                style={passedProps.style}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                containerStyle={{ marginVertical: 10, width: '95%' }}
                                textStyle={{ fontSize: 17 }}
                            >
                                {element.separatorTitle}
                            </Separator>
                        </Grid>
                    );
                case CHECKBOX:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomCheckBox
                                style={passedProps.style}
                                label={element.title}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                            />
                        </Grid>
                    );
                case DATETIMERANGE:
                    return (
                        <Grid
                            key={element.key}
                            style={passedProps.gridStyle || { paddingRight: 10 }}
                            item
                            xs={12}
                            sm={element.type === 2 ? 6 : element.type === 3 ? 3 : undefined}
                        >
                            <CustomDateRange
                                style={passedProps.style}
                                isRequired={element.required}
                                ref={(ref) => (passedProps.inputRefs[element.dataKey] = ref)}
                                value={element.value}
                                keyboardType={element.keyboardType}
                                editable={element.editable}
                                label={element.title}
                            />
                        </Grid>
                    );
                default:
                    return;
            }
    }
}
