import React from 'react';
const minDelay = 0;
const HomePage = React.lazy(() => {
    return Promise.all([import('../screens/HomePage'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});

const Appointments = React.lazy(() => {
    return Promise.all([import('../screens/Appointments'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});

//Yönetim
const Muratabi = React.lazy(() => {
    return Promise.all([import('../screens/Muratabi'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});

const CompanyInfo = React.lazy(() => {
    return Promise.all([import('../screens/management/CompanyInfo'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});
const Constants = React.lazy(() => {
    return Promise.all([import('../screens/management/Constants'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});

const Users = React.lazy(() => {
    return Promise.all([import('../screens/management/Users'), new Promise((resolve) => setTimeout(resolve, minDelay))]).then(
        ([moduleExports]) => moduleExports
    );
});

const Accounting = React.lazy(() => {
    return Promise.all([
        import('../screens/management/Accounting/index'),
        new Promise((resolve) => setTimeout(resolve, minDelay)),
    ]).then(([moduleExports]) => moduleExports);
});

const routes = [
    {
        name: 'Anasayfa',
        path: '/',
        role: 1,
        Component: HomePage,
    },
    {
        name: 'Randevular',
        path: '/Appointments',
        role: 1,
        Component: Appointments,
    },

    //Yönetim
    {
        name: 'Sabitler',
        path: '/Constants',
        role: 2,
        Component: Constants,
    },
    {
        name: 'Kullanıcı',
        path: '/Users',
        role: 2,
        Component: Users,
    },
    {
        name: 'Gider',
        path: '/Accounting',
        role: 2,
        Component: Accounting,
    },
    {
        name: 'Murat',
        path: '/Muratabi',
        role: 3,
        Component: Muratabi,
    },
    {
        name: 'Şirket Bilgileri',
        path: '/CompanyInfo',
        role: 1,
        Component: CompanyInfo,
    }
];
export default routes;
