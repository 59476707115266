import * as React from 'react';
import {
    Button,
    Dialog,
    TextField,
    FormControlLabel,
    IconButton,
    Checkbox,
    MenuItem,
    Link,
    Box,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    InputAdornment,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import cities from '../inputfields/tr-cities.json';
import { makeStyles } from '@mui/styles';
import ydkApi from '../Api/ydkApi';
import Commons from '../functions/Commons';
import logo from '../assets/logo01.jpg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import md5 from 'md5';
import api from '../functions/api';
import metrics from '../metrics';
import sessionwrapper from '../functions/sessionwrapper';
import parse from 'html-react-parser';
import vdJson from '../assets/vergidaireleri.json';

const ENTER_KEY = 13;
var email;
var password;

var user = {
    city: 1,
    companyName: "",
    taxNo: '',
    taxOffice: '',
    phone: "",
    email: '',
    //ThirdPage
    name: '',
    surname: '',
    id: '',
    agreementCB: false,
    amount: '',
    password: '',
    passwordRepeat: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    isError: true
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const FirstPage = (props) => {

    const login = (email, password) => {
        fetch(`${metrics.URL}/loginBeton`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: md5(password),
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.user != null) {
                    if (!json.user.agreement) {
                        sessionwrapper.setItem('user', json.user);
                        props.setOpenAgreement({ show: true, acceptButton: true });
                    } else {
                        sessionwrapper.setItem('user', json.user);
                        props.history.replace(`/`);
                    }
                    global.emitter.emit('updateRole');
                } else {
                    alert(json.message);
                }
            }).catch(() => {
                global.emitter.emit('change', 'error', 'Bir Hata Oluştu.');
            });
    };

    function _handleKeyDown(event) {
        switch (event.keyCode) {
            case ENTER_KEY:
                if (email && password) login(email, password);
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        document.addEventListener('keydown', _handleKeyDown);

        // returned function will be called on component unmount
        return () => {
            document.removeEventListener('keydown', _handleKeyDown);
        };
    });

    return (
        <form>
            <div>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(event) => (email = event.target.value)}
                    id="email"
                    label="E-Posta"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(event) => (password = event.target.value)}
                    name="password"
                    label="Parola"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Beni hatırla" />
            </div>
            <Button
                onClick={() => (password && email ? login(email, password) : null)}
                variant="contained"
                style={{ width: 200, borderRadius: 50, textTransform: 'capitalize' }}
                sx={{ mt: 2, mb: 2 }}
                color="primary"
            >
                Giriş
            </Button>
            <Grid container sx={{ mt: 2, mb: 3 }}>
                <Grid item xs>
                    {/*<Link style={{ cursor: 'pointer' }} variant="body2">
            Şifreni mi unuttun?
  </Link>*/}
                </Grid>
                <Grid item>
                    <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            props.setPageIndex(2);
                        }}
                        variant="body2"
                    >
                        Ücretsiz kullanım talep et!
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};
const SecondPage = (props) => {
    const classes = useStyles();

    const [error, setError] = React.useState({ companyName: false, taxNo: false, taxOffice: false, phone: false, email: false });
    const [update, setUpdate] = React.useState(true);

    const handleError = (event) => {
        if (event.target.name === "email") {
            Commons.validateEmail(event.target.value) ?
                setError(previous => ({ ...previous, [event.target.name]: false })) :
                setError(previous => ({ ...previous, [event.target.name]: true }));
            return;
        }
        if (event.target.value === "") {
            setError(previous => ({ ...previous, [event.target.name]: true }));
        } else {
            setError(previous => ({ ...previous, [event.target.name]: false }));
        }
    };
    const handleChange = (event) => {
        user[event.target.name] = event.target.value;
        setUpdate(!update)
        handleError(event);
    };
    const handleSubmit = () => {
        if (!user.companyName || !user.email || !user.phone || !user.taxNo || !user.taxOffice) {
            global.emitter.emit('change', 'error', 'Zorunlu alanlar boş bırakılamaz!');
            return;
        }
        props.setFormData({
            companyName: user.companyName,
            city: cities.filter((city) => city.number === user.city)[0],
            phone: user.phone,
            companyAddress: user.address,
            email: user.email,
            companyTaxNo: user.taxNo,
            taxOffice: vdJson[user.city].find((val) => val.kod === user.taxOffice).vdadi,
        });
        props.setPageIndex(3);
    };

    return (
        <Grid container>
            <Grid item xs={12} md={12} style={{ paddingRight: 10 }}>
                <TextField
                    id="companyName"
                    name="companyName"
                    size="small"
                    label="Şirket İsmi"
                    fullWidth
                    margin="normal"
                    required
                    value={user.companyName}
                    inputProps={{
                        name: 'companyName',
                        id: 'companyName',
                    }}
                    variant="standard"
                    onBlur={handleError}
                    onChange={handleChange}
                    error={error.companyName}
                    helperText={error.companyName ? 'Şirket ismi boş bırakılamaz.' : ''}
                />
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingRight: 10 }}>
                <TextField
                    id="signup-sehir"
                    name="city"
                    select
                    size="small"
                    label="Şehir"
                    fullWidth
                    value={user.city}
                    required
                    inputProps={{
                        name: 'city',
                        id: 'signup-sehir',
                    }}
                    onChange={handleChange}
                    variant="standard"
                >
                    {cities.map((option) => (
                        <MenuItem key={option.number} value={option.number}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    size="small"
                    id="taxNo"
                    label="Vergi Numarası"
                    name="taxNo"
                    variant="standard"
                    type="number"
                    value={user.taxNo}
                    onBlur={handleError}
                    onChange={handleChange}
                    error={error.taxNo}
                    helperText={error.taxNo ? 'Lütfen geçerli bir VKN giriniz.' : ''}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    id="signup-vd"
                    name="taxOffice"
                    select
                    margin="dense"
                    size="small"
                    label="Vergi Dairesi"
                    fullWidth
                    required
                    value={user.taxOffice}
                    inputProps={{
                        id: 'vd-select',
                    }}
                    onChange={handleChange}
                    onBlur={handleError}
                    variant="standard"
                >
                    {vdJson[user.city].map((option) => (
                        <MenuItem key={option.kod} value={option.kod}>
                            {option.vdadi}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingRight: 10 }}>
                <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    size="small"
                    id="phone"
                    label="Telefon"
                    name="phone"
                    type="number"
                    variant="standard"
                    value={user.phone}
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleError}
                    onChange={handleChange}
                    error={error.phone}
                    helperText={error.phone ? 'Telefon numarası boş bırakılamaz.' : ''}
                />
            </Grid>

            <Grid item xs={12} md={12} style={{ paddingRight: 10 }}>
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    size="small"
                    id="email"
                    label="E-Posta"
                    name="email"
                    variant="standard"
                    autoComplete="email"
                    value={user.email}
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.email}
                    helperText={error.email ? 'Lütfen geçerli bir e-posta adresi giriniz'
                        : 'Denetmen sistemine giriş için e-posta kullanılacaktır.'}
                />
            </Grid>
            <Grid container sx={{ mt: 3, mb: 2 }}>
                <Grid item xs>
                    <Button
                        variant="text"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                            props.setPageIndex(1);
                        }}
                    >
                        {'<- Geri'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        //type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        style={{ width: 200, borderRadius: 50, textTransform: 'capitalize' }}
                    >
                        İlerle
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ThirdPage = (props) => {
    const classes = useStyles();

    const [error, setError] = React.useState({ name: false, surname: false, id: false, password: false, passwordRepeat: false });
    const [agreement, setAgreement] = React.useState(false);

    const handleError = (event) => {
        if (event.target.name === "passwordRepeat") {
            event.target.value !== user.password ?
                setError(previous => ({ ...previous, [event.target.name]: true })) :
                setError(previous => ({ ...previous, [event.target.name]: false }));
            return;
        }
        if (event.target.value === "") {
            setError(previous => ({ ...previous, [event.target.name]: true }));
        } else {
            setError(previous => ({ ...previous, [event.target.name]: false }));
        }
    };
    const handleChange = (event) => {
        user[event.target.name] = event.target.value;
        handleError(event);
    };
    // TODO düzenlenecek.

    const handleClickShowPassword = () => {
        user.showPassword = !user.showPassword;
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onHandleSubmit = () => {
        var formData = {
            firstName: user.name,
            surName: user.surname,
            idNumber: user.id,
            password: md5(user.password),
            visible: true,
            role: 3, //Admin,
            status: true,
        };
        if (agreement) {
            props.handleSendDemoRequest(formData);
        } else {
            global.emitter.emit('change', 'error', "Gizlilik ve kullanım sözleşmesi'ni kabul etmeniz gerekmektedir.");
        }
    };

    return (
        <Grid container justify="space-around">
            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    variant="standard"
                    id="firstName_label"
                    size="small"
                    label="Ad"
                    name="name"
                    value={user.name}
                    helperText={error.name ? 'Lütfen adınızı giriniz' : ''}
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.name}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    size="small"
                    variant="standard"
                    id="lastname_label"
                    label="Soyad"
                    name="surname"
                    value={user.surname}
                    helperText={error.name ? 'Lütfen soyadınızı giriniz' : ''}
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.surname}
                />
            </Grid>
            <Grid item xs={12} md={8} style={{ paddingRight: 10 }}>
                <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    id="idNumber"
                    size="small"
                    label="T.C. Kimlik Numarası"
                    name="id"
                    variant="standard"
                    type="number"
                    value={user.id}
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.id}
                    helperText={error.id ? 'Lütfen geçerli bir tc kimlik numarası giriniz' : ''}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    id="passwordInput"
                    type={user.showPassword ? 'text' : 'password'}
                    value={user.password}
                    label="Parola"
                    size="small"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.password}
                    helperText={error.password ? 'Lütfen bir parola giriniz.' : ''}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {user.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    labelWidth={70}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                <TextField
                    id="outlined-adornment-password"
                    type={user.showPassword ? 'text' : 'password'}
                    value={user.passwordRepeat}
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    label="Parola tekrarı"
                    variant="standard"
                    name="passwordRepeat"
                    onChange={handleChange}
                    onBlur={handleError}
                    error={error.passwordRepeat}
                    helperText={error.passwordRepeat ? 'Parolalar uyuşmuyor' : ''}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {user.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
            <Grid item xs={12} marginTop={2} justifyContent="center" alignItems="center" md={12}>
                <Checkbox
                    checked={agreement}
                    onChange={(e) => {
                        setAgreement(e.target.checked);
                    }}
                    style={{ padding: 0, marginRight: 5 }}
                />
                <Link
                    style={{
                        fontSize: 12,
                        cursor: 'pointer',
                    }}
                    onClick={() => props.setOpenAgreement({ show: true, acceptButton: false })}
                    variant="body2"
                >
                    Gizlilik ve kullanım sözleşmesi
                </Link>
                <label style={{ fontSize: 12 }}>'ni okudum, kabul ediyorum.</label>
            </Grid>

            <Grid container sx={{ mt: 3, mb: 3 }}>
                <Grid item xs md={6}>
                    <Button
                        variant="text"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                            props.setPageIndex(2);
                        }}
                    >
                        {'<- Geri'}
                    </Button>
                </Grid>
                <Grid item md={6}>
                    <Button
                        //type="submit"
                        onClick={onHandleSubmit}
                        fullWidth
                        disabled={props.submitDisable}
                        variant="contained"
                        style={{ width: 200, borderRadius: 50, textTransform: 'capitalize' }}
                    >
                        {props.submitDisable ? "Lütfen bekleyiniz..." : "Kayıt Ol"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
const SignUp = (props) => {
    const classes = useStyles();
    let query = useQuery();
    const defaultPageIndex = query.get('page') === 'demo' ? 2 : 1;
    const [pageIndex, setPageIndex] = React.useState(defaultPageIndex);
    const [formData, setFormData] = React.useState({});
    const [openAgreement, setOpenAgreement] = React.useState({ show: false, acceptButton: false });
    const [agreementHTML, setAgreementHTML] = React.useState('');
    const [submitDisable, setSubmitDisable] = React.useState(false);

    React.useEffect(() => {
        api.getAgreement().then((data) => {
            if (data) {
                setAgreementHTML(data.agreement);
            }
        });
    }, []);

    const handleSendDemoRequest = (userDetails) => {
        setSubmitDisable(true);
        ydkApi.signUpVerify({ ...userDetails, ...formData })
            .then(res => {
                if (res?.type === "success") {
                    global.emitter.emit('change', res.type, res.message);
                    setTimeout(() => { props.history.replace(`/`); setSubmitDisable(false); }, 3000);
                } else {
                    global.emitter.emit('change', res?.type, res?.message);
                    setSubmitDisable(false);
                }
            })
    };
    // Privacy policy
    function accept() {
        api.acceptAgreement().then((res) => {
            setOpenAgreement({ ...openAgreement, show: false });
            props.history.replace(`/`);
        });
    }
    const handleCloseAgreement = () => {
        setOpenAgreement({
            ...openAgreement,
            show: false,
        });
        if (sessionwrapper.getItem('user')) {
            sessionwrapper.clearAll();
        }
    };
    return (
        <div className={classes.container}>
            <Dialog
                open={openAgreement.show}
                onClose={handleCloseAgreement}
                scroll="paper"
                maxWidth="sm"
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="scroll-dialog-title">
                    Denetmen Gizlilik ve Kullanım Sözleşmesi
                </DialogTitle>
                <DialogContent style={{ flexGrow: 1 }} dividers>
                    {parse(agreementHTML)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAgreement} color="primary">
                        {'Kapat'}
                    </Button>
                    {openAgreement.acceptButton && (
                        <Button onClick={accept} color="primary">
                            {'Okudum ve Kabul Edİyorum'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Grid
                container
                component="main"
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px',
                    overflow: 'hidden',
                    borderRadius: 6,
                }}
            >
                <Grid item style={{ width: '100%', position: 'relative' }} md={6} sm={12}>
                    <Box style={{ position: 'absolute', top: '40%', bottom: '50%', left: 30, color: 'whitesmoke' }}>
                        <Typography variant="h4" style={{ textShadow: 'rgb(0 0 0 / 61%) 0px 0px 19px' }} component="div">
                            Denetmen'e Hoşgeldiniz
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ textShadow: 'rgb(0 0 0 / 61%) 0px 0px 19px', fontWeight: 'lighter' }}
                            gutterBottom
                            component="div"
                        >
                            Yapı denetimin dijital hali...
                        </Typography>
                    </Box>
                    <div style={{ position: 'absolute', bottom: 20, left: 15 }}>
                        <IconButton
                            onClick={() => Commons.openInNewTab('https://www.linkedin.com/company/49172573')}
                            style={{ color: 'whitesmoke' }}
                        >
                            <LinkedInIcon style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px' }} />
                        </IconButton>
                        <IconButton style={{ color: 'whitesmoke' }}>
                            <InstagramIcon style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px' }} />
                        </IconButton>
                        <IconButton style={{ color: 'whitesmoke' }}>
                            <TwitterIcon style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px' }} />
                        </IconButton>
                    </div>
                    <Link
                        underline="none"
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            cursor: 'pointer',
                            fontWeight: 'lighter',
                            bottom: 30,
                            color: 'whitesmoke',
                            left: '50%',
                            transform: 'translate(-50%)',
                        }}
                        onClick={() => setOpenAgreement({ show: true, acceptButton: false })}
                        variant="body2"
                    >
                        Gizlilik Sözleşmesi
                    </Link>
                    <Link
                        underline="none"
                        style={{
                            position: 'absolute',
                            fontSize: 13,
                            cursor: 'pointer',
                            fontWeight: 'lighter',
                            bottom: 30,
                            color: 'whitesmoke',
                            right: 25,
                        }}
                        href="https://shiftsoft.co/"
                        target="blank"
                        variant="body2"
                    >
                        © shiftsoft
                    </Link>
                    <img
                        alt="Denetmen logo"
                        style={{
                            display: 'block',
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%',
                        }}
                        src={logo}
                    />
                </Grid>
                <Grid className={classes.secondBox} item md={6} sm={12}>
                    <Typography
                        component="h1"
                        variant="h4"
                        style={{ textShadow: 'rgb(23 94 126) 0px 0px 2px', color: '#175e7e', marginBottom: 12, fontWeight: 'bold' }}
                    >
                        {pageIndex === 1 ? 'Giriş yap' : 'Ücretsiz Kullanın!'}
                    </Typography>
                    <Box style={{ alignItems: 'center', alignSelf: 'center' }}>
                        {pageIndex === 1 && (
                            <FirstPage
                                {...props}
                                setOpenAgreement={(val) => setOpenAgreement(val)}
                                setPageIndex={(p) => setPageIndex(p)}
                                setFormData={(p) => setFormData(p)}
                            />
                        )}
                        {pageIndex === 2 && (
                            <SecondPage
                                setOpenAgreement={(val) => setOpenAgreement(val)}
                                setPageIndex={(p) => setPageIndex(p)}
                                setFormData={(p) => setFormData(p)}
                            />
                        )}
                        {pageIndex === 3 && (
                            <ThirdPage
                                setOpenAgreement={(val) => setOpenAgreement(val)}
                                setPageIndex={(p) => setPageIndex(p)}
                                handleSendDemoRequest={(p) => {
                                    handleSendDemoRequest(p);
                                }}
                                submitDisable={submitDisable}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Copyright style={{ marginBottom: 22, marginTop: 25 }} />
        </div>
    );
};
const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        overflow: 'auto',
        paddingTop: 80,
        paddingLeft: 180,
        paddingRight: 180,
        [theme.breakpoints.down('md')]: {
            paddingTop: 50,
            paddingLeft: 20,
            paddingRight: 20,
        },
        backgroundColor: '#e2ecec',
    },
    secondBox: {
        backgroundColor: 'white',
        overflow: 'auto',
        paddingTop: 50,
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    large: {
        minWidth: theme.spacing(7),
        minHeight: theme.spacing(7),
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://shiftsoft.co/" target="blank">
                shiftsoft.co
            </Link>
            {' all rights reserved '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default SignUp;
